
/**
 *     --direction: ltr;
    --font-stack-headings: "DM Sans", sans-serif;
    --font-weight-headings: 500;
    --font-style-headings: normal;
    --font-stack-body: "DM Sans", sans-serif;
    --font-weight-body: 400;
    --font-weight-body-bold: 500;
    --font-style-body: normal;
    --font-weight-buttons: var(--font-weight-body-bold);
    --font-weight-menu: var(--font-weight-body-bold);
    --base-headings-size: 70;
    --base-headings-line: 1.2;
    --base-body-size: 17;
    --base-body-line: 1.5;
    --base-menu-size: 16;
    --color-background-header: #ffffff;
    --color-secondary-background-header: rgba(29, 29, 29, 0.08);
    --color-opacity-background-header: rgba(255, 255, 255, 0);
    --color-text-header: #1d1d1d;
    --color-foreground-header: #fff;
    --color-accent-header: #000000;
    --color-foreground-accent-header: #fff;
    --color-borders-header: rgba(29, 29, 29, 0.1);
    --color-background-main: #ffffff;
    --color-secondary-background-main: rgba(29, 29, 29, 0.08);
    --color-third-background-main: rgba(29, 29, 29, 0.04);
    --color-fourth-background-main: rgba(29, 29, 29, 0.02);
    --color-opacity-background-main: rgba(255, 255, 255, 0);
    --color-text-main: #1d1d1d;
    --color-foreground-main: #fff;
    --color-secondary-text-main: rgba(29, 29, 29, 0.62);
    --color-accent-main: #000000;
    --color-foreground-accent-main: #fff;
    --color-borders-main: rgba(29, 29, 29, 0.15);
    --color-background-cards: #ffffff;
    --color-gradient-cards: ;
    --color-text-cards: #1d1d1d;
    --color-foreground-cards: #fff;
    --color-secondary-text-cards: rgba(29, 29, 29, 0.6);
    --color-accent-cards: #000000;
    --color-foreground-accent-cards: #fff;
    --color-borders-car
    --gutter-small: 15px;
    --gutter-regular: 25px;
    --gutter-large: 50px;
    --gutter-xlarge: 80px;
    --gutter-container: 40px;
    --sidebar-width: 420px;
    --sidebar-gutter: 40px;
    --full-height: 100vh;
    --button-padding-large: .9375rem 2.875rem;
    --button-padding-regular: .6875rem 2.25rem;
    --button-padding-small: .5rem 1.625rem;
    --input-padding: .75rem;
    --button-product: 3.75rem;
    --header-vertical-space: 30px;
    --container-vertical-space: var(--container-vertical-space-base);
    --grid-gap: var(--grid-gap-original-base);
    
    
    
//************************************************* 
    ds: #e1e1e1;
    --color-background-footer: #ffffff;
    --color-text-footer: #000000;
    --color-accent-footer: #298556;
    --color-borders-footer: rgba(0, 0, 0, 0.15);
    --color-borders-forms-primary: rgba(29, 29, 29, 0.3);
    --color-borders-forms-secondary: rgba(29, 29, 29, 0.6);
    --border-width-cards: 1px;
    --border-radius-cards: 10px;
    --border-width-buttons: 1px;
    --border-radius-buttons: 30px;
    --border-width-forms: 1px;
    --border-radius-forms: 5px;
    --shadow-x-cards: 0px;
    --shadow-y-cards: 0px;
    --shadow-blur-cards: 0px;
    --color-shadow-cards: rgba(0,0,0,0);
    --shadow-x-buttons: 0px;
    --shadow-y-buttons: 0px;
    --shadow-blur-buttons: 0px;
    --color-shadow-buttons: rgba(0,0,0,0);
    --grid-gap-original-base: 26px;
    --container-vertical-space-base: 100px;
    --image-fit-padding: 5%;

//*************************************


 */
/**
 * Colors palettes for the MUI theme.
 * Defines colors for primary and secondary elements, background, and text.
 * 
 * */
 

export const template1Palette = {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    background: {
      default: '#ffffff',
      paper: '#f5f5f5',
      header:"#ffffff",
      Buttom:'#1d1d1d',
      sidebar:'#f5f5f5cc',
      footer:'transparent',
      cartHead:'#0000000d',
      hover:{
        checkedbox: 'rgba(25, 118, 210, 0.1)'
      }
    },
    text: {
      primary: '#000000',
      secondary: '#333333',
      primaryButton:"#1d1d1d",
      colorForeground: '#fff',
      unchecked: '#B0BEC5',
      checked: '#1976d2',
      disabled:{
        checkedbox:'#E0E0E0',
      },
       hover:{
        primary : '#298556'
       }
    },
    border:{
      header:'#1d1d1d1a', 
      input:'#1d1d1d1a', 
      footer:'#1d1d1d1a', 
      main:'rgba(29, 29, 29, 0.15)',
      focus:{
        checkedbox:"#1976d2"
      }
      
    },
    font:{
      fontWeightMenu:500
    }
  };
  
  export const template2Palette = {
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#f48fb1',
    },
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
    text: {
      primary: '#ffffff',
      secondary: '#cccccc',
    },
  };



export default {

    FRENCH: {

 MISSING_REQUIRED_DATA_EMAIL :"Données requise manquante : Email",
CUSTOMER_NOT_FOUND:"Le client n'a pas été trouvé",
CUSTOMER_NOT_ACTIF:"Le client n'est pas actif",
DUPLICATED_EMAIL:"Email dupliqué",
SMTP_CANNOT_BE_NULL:"Un problème est survenu lors de l'envoi de votre code de réinitialisation du mot de passe", 
MISSING_REQUIRED_DATA_SECUTITY_CODE:"Données requise manquante : Code de sécurité", 
WRONG_SECURITY_CODE :"Code de sécurité incorrect",
SECURITY_CODE_EXPIRED:"Code de sécurité expiré",
ECOMMERCE_IS_NOT_CONFIGURED_FOR_THIS_STORE:"E-commerce n'est pas configuré",
ECOMMERCE_IS_NOT_ACTIVE:"E-commerce n'est pas actif",
INVALID_USERNAME_PASSWORD_FOR_ECOMMERCE_CUSTOMER:"Email ou mot de passe du client incorrect",
OPERATION_FAILED: "Opération échouée",
MISSING_REQUIRED_DATA_EMAIL:"Données requise manquante : Email",
MISSING_REQUIRED_DATA_NAME:"Données requise manquante : Raison sociale",
MISSING_REQUIRED_DATA_LAST_NAME:"Données requise manquante : Nom",
MISSING_REQUIRED_DATA_FIRST_NAME :"Données requise manquante : Prénom",
DUPLICATED_CUSTOMER_EMAIL:"Email dupliqué",
"SMTP_?_CANNOT_BE_NULL":"Un problème est survenu lors de l'envoi de votre mot de passe",
ARTICLE_NOT_FOUND:"Article non trouvé",
ARTICLE_NOT_ACTIF:"Article non actif",
ARTICLE_NOT_AVAILABLE_FOR_SALE:"Article non disponible à la vente",
ARTICLE_NOT_AVAILABLE_ONLINE:"Article non disponible en ligne",
WEB_ONLINE_START_DATE_BEFORE_TODAY:"L’article n’est pas disponible actuellement",
WEB_ONLINE_DEADLINE_EXPIRED:"L’article n’est pas disponible actuellement",
ARTICLE_TYPE_NOT_SIMPLE:"Type d'article non simple",


    },
    ENGLISH: {

 MISSING_REQUIRED_DATA_EMAIL:"Missing required data: Email",
CUSTOMER_NOT_FOUND:"Customer not found",
CUSTOMER_NOT_ACTIF:"Customer is not active",
DUPLICATED_EMAIL:"Duplicate email",
SMTP_CANNOT_BE_NULL:"There was a problem sending your password reset code", 
MISSING_REQUIRED_DATA_SECUTITY_CODE:"Missing required data: Security code",
WRONG_SECURITY_CODE:"Incorrect security code",
SECURITY_CODE_EXPIRED:"Expired security code",
ECOMMERCE_IS_NOT_CONFIGURED_FOR_THIS_STORE:"E-commerce is not configured", 
ECOMMERCE_IS_NOT_ACTIVE:"E-commerce is not active",
INVALID_USERNAME_PASSWORD_FOR_ECOMMERCE_CUSTOMER:"Email or incorrect client pass",
OPERATION_FAILED: "Operation failed", 
MISSING_REQUIRED_DATA_EMAIL:"Missing required data: Email",
MISSING_REQUIRED_DATA_NAME:"Missing required data: Company name",
MISSING_REQUIRED_DATA_LAST_NAME:"Missing required data: Last name",
MISSING_REQUIRED_DATA_FIRST_NAME:"Missing required data: First name",
DUPLICATED_CUSTOMER_EMAIL:"Duplicate email",
"SMTP_?_CANNOT_BE_NULL":"A problem occurred while sending your password",
ARTICLE_NOT_FOUND:"Article not found",
ARTICLE_NOT_ACTIVE:"Article not active",
ARTICLE_NOT_AVAILABLE_FOR_SALE:"Article not available for sale",
ARTICLE_NOT_AVAILABLE_ONLINE:"Article not available online",
WEB_ONLINE_START_DATE_BEFORE_TODAY:"Article is not currently available",
WEB_ONLINE_DEADLINE_EXPIRED:"Article is currently not available",
ARTICLE_TYPE_NOT_SIMPLE:"Article type not simple",
    }
}
import React, { createContext, useContext, useEffect,useState, useReducer } from "react"; 
import default_logo from "../images/logo/mybe.svg";  
//import {useGetAllProducts, useGetTokenByLicenceNumber,} from "./hooks/index"; 
import { LinearProgress } from "@mui/material";
import { isGlobalLoadingState, setGlobalLoadingState } from "utils";

const SettingsAppsContext = createContext();
const SettingsAppsDispatchContext = createContext();
var dontGetGlobalData=false;
/**
 * Reducer function for managing settings state.
 * Handles updates to the logo and licence code.
 *
 * @param {Object} state - The current settings state.
 * @param {Object} action - The action to perform.
 * @param {string} action.type - The type of action.
 * @param {string} action.payload - The value to set for the action type.
 * @returns {Object} The updated settings state.
 */
function settingsReducer(state, action) {
  switch (action.type) {
    case 'ALL':{ /*
      localStorage.setItem("logo", JSON.stringify(action.payload?.logo));
      localStorage.setItem("codeShop", JSON.stringify(action.payload?.codeShop)); 
      localStorage.setItem("categories", JSON.stringify(action.payload?.categories));*/

      return { ...state,...action.payload };
    }
    case "LOGO":{
      localStorage.setItem("logo", JSON.stringify(action.payload));
      return { ...state, logo: action.payload };
    }
    case "LICENCE_CODE": {
      localStorage.setItem("codeShop", JSON.stringify(action.payload));  
      return { ...state, codeShop: action.payload };
    }
    case "LICENCE_AUTH":{  
      localStorage.setItem("codeShop", action.payload?.codeShop );   

      let newLicencesData=({...state.licencesData});
      try {
        if(newLicencesData[action.payload?.codeShop])
        newLicencesData = {...newLicencesData,[action.payload?.codeShop]:{...newLicencesData[action.payload?.codeShop],token:action.payload?.licenceData?.token  }};
        else 
        newLicencesData = {...newLicencesData,[action.payload?.codeShop]:{...action.payload?.licenceData}};
      
      } catch (error) {
        newLicencesData = {...newLicencesData,[action.payload?.codeShop]:{...action.payload?.licenceData}};
      }  
      localStorage.setItem("licencesData",  JSON.stringify(newLicencesData || null));   
      window.location.reload();
      return { ...state, codeShop: action.payload?.codeShop ,licenceAuth:true,licencesData:newLicencesData};
    }
    case "IS_LOADING":{
       return { ...state, isLoading: action.payload };
    } 
    case "CATEGORIES":{
      localStorage.setItem("categories", JSON.stringify(action.payload));    
       return { ...state, categories: action.payload };
    }  
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
} 

/**
 * Provider component for managing application settings.
 * Provides context for settings state and dispatch function.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to render.
 * @returns {JSX.Element} The `SettingsAppsProvider` component.
 */
const SettingsAppsProvider = ({ children }) => { 




  const [state, dispatch] = useReducer(settingsReducer, {
    logo: localStorage.getItem("logo") ? JSON.parse(localStorage.getItem("logo")) : default_logo,
    codeShop: localStorage.getItem("codeShop") ? localStorage.getItem("codeShop"): null ,
    licenceAuth: localStorage.getItem("licencesData") && localStorage.getItem("codeShop") && !!JSON.parse(localStorage.getItem("licencesData"))[localStorage.getItem("codeShop")],
    licencesData: localStorage.getItem("licencesData") ? JSON.parse(localStorage.getItem("licencesData")) :null,
    userlicenceData :localStorage.getItem("licencesData") && localStorage.getItem("codeShop") ? JSON.parse(localStorage.getItem("licencesData"))[localStorage.getItem("codeShop")] :null,
    categories: localStorage.getItem("categories") ? JSON.parse(localStorage.getItem("categories")): [],
    isLoading:true, 
  });   

  return (
    <SettingsAppsContext.Provider value={state}>
      <SettingsAppsDispatchContext.Provider value={dispatch}> 
        {children}
      </SettingsAppsDispatchContext.Provider>
    </SettingsAppsContext.Provider>
  );
};

/**
 * Hook to access the settings state.
 * Throws an error if used outside of `SettingsAppsProvider`.
 *
 * @returns {Object} The settings state.
 */
const useSettingsApps = () => {
  const context = useContext(SettingsAppsContext);
  if (context === undefined) {
    throw new Error("useSettingsApps must be used within a SettingsAppsProvider");
  }
  return context;
};

/**
 * Hook to access the settings dispatch function.
 * Throws an error if used outside of `SettingsAppsProvider`.
 *
 * @returns {Function} The settings dispatch function.
 */
const useSettingsAppsDispatch = () => {
  const context = useContext(SettingsAppsDispatchContext);
  if (context === undefined) {
    throw new Error("useSettingsAppsDispatch must be used within a SettingsAppsProvider");
  }
  return context;
};

/**
 * Dispatches an action to update the settings state.
 * Currently supports updating the logo and licence code.
 *
 * @param {Function} dispatch - The dispatch function from `useReducer`.
 * @param {Object} payload - The payload to update in the state.
 */
const setSettingsApps = (dispatch, payload) => {  
  dispatch({
    type: "SETTINGS_APPS",
    payload: payload,
  });
};


const setLicenceCodeApps = (dispatch, payload) => {  
  dispatch({
    type: "LICENCE_CODE",
    payload: payload,
  });
};


const setCategoriesApps = (dispatch, payload) => {  
  dispatch({
    type: "CATEGORIES",
    payload: payload,
  });
};
const setLicenceAuth = (dispatch, payload) => {  
  dispatch({
    type: "LICENCE_AUTH",
    payload: payload,
  });
};


export { SettingsAppsProvider, useSettingsApps, useSettingsAppsDispatch,setLicenceCodeApps, setLicenceAuth,setCategoriesApps,setSettingsApps };

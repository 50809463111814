import { useGetProducts,useGetProductsByCategories } from "services/articles";
import { usePromise } from "services/index";
import { useGetProductsCategories } from "services/categories/index"; 
import { getTokenOrSignInByLicenceNumber, useGetTemplateData } from "services/licence";
import { jwtDecode } from "jwt-decode";
import { useState } from "react";


export const useGetAllProducts=()=>{
    
const {result,request} = usePromise(useGetProducts,{
    onSuccess:(data)=>{ 
    }
})
    return {
        allProducts:result?.data,
       getAllProducts:request
    }
}; 

export const useProductsByCategories=()=>{
    
const {result,request} = usePromise(useGetProductsByCategories,{ 
})
    return {
        productsByCategories:result?.data,
        getProductsByCategories:request
    }
}
 


export const useGetProductsCategoriesApi=()=>{ 
const {result,request} = usePromise(useGetProductsCategories )
    return {
        allProductsCategories:result?.data,
       getProductsCategories:request
    }
};

/*
export const useGetAllProducts=()=>{
    
    const {result,request} = usePromise(useGetProductsCategories )
        return {
            allProductsCategories:result?.data,
           getProductsCategories:request
        }
    }

    

    export const useGetAllProducts=()=>{
    
        const {result,request} = usePromise(useGetProductsCategories )
            return {
                allProductsCategories:result?.data,
               getProductsCategories:request
            }
        }
        
*/

export const useGetTokenByLicenceNumber = (callback=()=>null) => {
    const [licenceDataDataToken, setLicenceDataDataToken] = useState(null); // State to store decoded token
    const {  request } = usePromise(getTokenOrSignInByLicenceNumber, {
      onSuccess: async (data) => {
        if (data?.data) {
        //    localStorage.setItem("token", JSON.stringify((action.payload?.token || "").toString() || null));  
          const decoded = await jwtDecode(data.data); // Decode token
          setLicenceDataDataToken({...decoded, token:data?.data}); // Set the decoded token in state
          callback(1);
        }
      },
      onFailure:()=>callback()
    });
  
    return { 
        licenceData: licenceDataDataToken, // Decoded token data
        getTokenByLicenceNumber: request, // Function to initiate the request
    };
  };
  
    

  export const useGetTemplateDataApi=()=>{
  const [templateData,setTemplateData] = useState();


      const { request} = usePromise(useGetTemplateData,{
        onSuccess:({data})=>{
             if(data)
                setTemplateData(data);
        }
      } )
          return {
            templateData ,
            setTemplateData,
             getTemplateData:request
          }
      }
      
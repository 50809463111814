import './App.css';  
import { memo, lazy, useCallback, Suspense, useMemo } from "react";  
import { LinearProgress } from '@mui/material';

import { useGlobalState } from 'context/GlobalStateContext';
const Template1 = lazy(() => import("template/template1/layout/index")); 

/**
 * App component is the main entry point of the application.
 * It selects and renders a template based on the `codeShop` retrieved from the `SettingsApps` context.
 * The selected template is lazily loaded and displayed with a fallback message while loading.
 *
 * @author Helmi Ben Eroi
 * @date 2024-08-16
 *
 * @returns {JSX.Element} The main application component.
 */
function App() { 
  const {templateData:{templateCode} } = useGlobalState();

  /**
   * Selects and renders the appropriate template based on the `codeShop`.
   * Uses `Suspense` to display a fallback message while the template is being loaded.
   *
   * @returns {JSX.Element} The selected template component with a loading fallback.
   */
  const Template = useCallback(({templateCode}) => {
    return (
      <Suspense fallback={<LinearProgress color="inherit" />}>
        {templateCode === 'HORIZON' ? <Template1 /> : <Template1 />} 
      </Suspense>
    );
  }, []); 
    const template = useMemo(()=><Template templateCode={templateCode}/>,[templateCode])
  return (
    <div className="App" data-testid={'App'}> 
      {template}
    </div>
  );
};

export default memo(App);

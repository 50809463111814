import { MaskOnBlur } from "utils";

export default {
    FRENCH: {
        DESCRIPTION: "Description",
        FREE_IN_N_HOURS_FROM: (days = 2, price = 300,symbol,precision) => `DÉLAI DE LIVRAISON ${days * 24}H`,/*H À PARTIR DE ${MaskOnBlur(price,symbol,precision)} */
        ADD_TO_CART: 'Ajouter au panier',
        REFERENCE: (ref) => `Référence : ${ref}`,
        SIGN_IN: 'Connexion',
        ALREADY_HAVE_AN_ACCOUNT: 'Vous avez déjà un compte?',
        CREATE: 'Créer',
        PASSWORD: 'Mot de passe',
        EMAIL: 'Email',
        LAST_NAME: 'Nom',
        FIRST_NAME: 'Prénom',
        FIELD_REQUIRED: "Ce champ est obligatoire",
        INVALID_EMAIL_ADDRESS: "Adresse e-mail non valide",
        CUSTOMER_LOGIN: 'Connexion client',
        NEW_CUSTOMER_SIGN_UP: "Nouveau client? S'enregistrer!",
        ACCOUNT: 'Compte',
        CREATE_ACCOUNT: 'Créer un compte',
        LANGUAGE: 'Langue',
        ENGLISH: 'Anglais',
        FRENCH: 'Français',
        FORGOT_PASSWORD: "Mot de passe oublié ?",
        TITLE_FORGOT_PASSWORD: "Mot de passe oublié",
        NEXT: "Suivant",
        ENTER_CODE: "Saisir le code",
        CONFIRMATION: "Confirmation",
        CHANGE: "Changer",
        PASSWORD_MUST_BE_AT_LEAST_6_CHARACTERS: "Mot de passe doit être au moins de 6 caractères",
        PASSWORDS_ARE_NOT_THE_SAME: "Les mots de passe ne sont pas identiques",
        PLEASE_CONFIRM_YOUR_NEW_PASSWORD: 'Veuillez confirmer votre nouveau mot de passe',
        IS_COMPANY: 'Société',
        COMPANY_NAME: "Raison sociale",

        CELLPHONE: "Mobile",
        CREATE_AN_ACCOUNT: "Créer un compte",
        MY_ACCOUNT: "Mon compte",
        OPERATION_CHANGE_PASSWORD_SUCCESS: 'Votre mot de passe a été modifié avec succès !',
        OPERATION_SUCCESS: "Opération effectuée avec succès",
        PRODUCTS_LEFT: (stoke = 0) => stoke > 0 ? `Il reste ${stoke} produits` : 'Epuisé',
        SHOP_NOW: "Acheter",
        SEARCH: "Chercher",
        PRODUCT: 'Produit',
        TOTAL: 'Total',
        QTY: 'Qté',
        REMOVE: 'Supprimer',
        CHECK_OUT: "Passer à la caisse",
        ARTICLE_NOT_FOUND: "Article non trouvé",
        ARTICLE_NOT_ACTIF: "Article non actif",
        ARTICLE_NOT_AVAILABLE_FOR_SALE: "Article non disponible à la vente",
        ARTICLE_NOT_AVAILABLE_ONLINE: "Article non disponible en ligne",
        WEB_ONLINE_START_DATE_BEFORE_TODAY: "L’article n’est pas disponible actuellement",
        WEB_ONLINE_DEADLINE_EXPIRED: "L’article n’est pas disponible actuellement",
        ARTICLE_TYPE_NOT_SIMPLE: "Type d'article non simple"

    }, 
    ENGLISH: {
        DESCRIPTION: "Description",
        FREE_IN_N_HOURS_FROM: (days = 2, price = 300,symbol,precision) => `DELIVERY DEADLINE ${days * 24}H`,/** FROM ${MaskOnBlur(price, symbol,precision)} */
        ADD_TO_CART: 'Add to cart',
        REFERENCE: (ref) => `Reference : ${ref}`,
        SIGN_IN: 'Sign in',
        ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',
        CREATE: 'Create',
        PASSWORD: 'Password',
        EMAIL: 'Email',
        LAST_NAME: 'Last name',
        FIRST_NAME: 'First name',
        FIELD_REQUIRED: "This field is required",
        INVALID_EMAIL_ADDRESS: 'Invalid email address',
        CUSTOMER_LOGIN: 'Customer Login',
        NEW_CUSTOMER_SIGN_UP: 'New Customer? Sign Up!',
        ACCOUNT: 'Account',
        CREATE_ACCOUNT: 'Create an account',
        LANGUAGE: 'language',
        ENGLISH: 'English',
        FRENCH: 'French',
        FORGOT_PASSWORD: "Forgot password ?",
        TITLE_FORGOT_PASSWORD: "Forgot password",
        NEXT: "Next",
        ENTER_CODE: "Enter the code",
        CONFIRMATION: "Confirmation",
        CHANGE: "Change",
        PASSWORD_MUST_BE_AT_LEAST_6_CHARACTERS: "Password must be at least 6 characters",
        PASSWORDS_ARE_NOT_THE_SAME: "Passwords are not the same",
        PLEASE_CONFIRM_YOUR_NEW_PASSWORD: 'Please confirm your new password',

        IS_COMPANY: 'Company',
        COMPANY_NAME: "Company name",

        CELLPHONE: "Cell phone",
        CREATE_AN_ACCOUNT: "Create an account",
        MY_ACCOUNT: "My account",
        OPERATION_SUCCESS: "Operation completed successfully",
        OPERATION_CHANGE_PASSWORD_SUCCESS: 'Your password has been successfully changed !',


        PRODUCTS_LEFT: (stoke = 0) => stoke > 0 ? `There are ${stoke} products left` : 'Sold Out',
        SHOP_NOW: "Shop Now",
        SEARCH: "Search",
        PRODUCT: 'Product',
        TOTAL: 'Total',
        QTY: 'Qty',
        REMOVE: 'Remove',
        CHECK_OUT: "Check Out",
        ARTICLE_NOT_FOUND: "Article not found",
        ARTICLE_NOT_ACTIF: "Article not active",
        ARTICLE_NOT_AVAILABLE_FOR_SALE: "Article not available for sale",
        ARTICLE_NOT_AVAILABLE_ONLINE: "Article not available online",
        WEB_ONLINE_START_DATE_BEFORE_TODAY: "Article is not currently available",
        WEB_ONLINE_DEADLINE_EXPIRED: "Article is not currently available",
        ARTICLE_TYPE_NOT_SIMPLE: "Article type not simple"


    }
}
import { LinearProgress } from "@mui/material";
import React, { createContext, useContext, useEffect, useReducer } from "react";  
import { useGetProductsCategoriesApi, useGetTemplateDataApi } from "template/global/hooks";
import { sumItemInArrayObject } from "utils";

const GlobalStateContext = createContext();
const GlobalStateDispatchContext = createContext();

const handlerShoppingCart=(categorie,shoppingCart,method)=>{
  let copyShoppingCart = {...shoppingCart};
  if(method === 'DELETE'){
   const shoppingCartCategorie = shoppingCart?.listCategories.find(item=> categorie?.id === item?.id);
   const totalPriceShoppingCartCategorie = shoppingCartCategorie?.qte * shoppingCartCategorie.principlePriceInclTax;
   const newList = copyShoppingCart?.listCategories.filter(item=> categorie?.id !== item?.id) || [];
   copyShoppingCart= {...copyShoppingCart, 
    total:copyShoppingCart?.total - totalPriceShoppingCartCategorie,
    listCategories:newList,
    nbCategories:sumItemInArrayObject(newList,'qte')
   }
  }
  else {
    const shoppingCartCategorieIndex = shoppingCart?.listCategories.findIndex(item=> categorie?.id === item?.id);
    let newList = [...shoppingCart?.listCategories];
    if(shoppingCartCategorieIndex >= 0){
      newList[shoppingCartCategorieIndex] = {...newList[shoppingCartCategorieIndex],qte:(newList[shoppingCartCategorieIndex]?.qte || 0) + categorie?.qte}
   
    }else{
      newList.push({ ...categorie })
    }
   const totalCategorie = categorie?.qte * categorie.principlePriceInclTax;



   copyShoppingCart= {...copyShoppingCart, 
    total:copyShoppingCart?.total + totalCategorie,
    listCategories:newList,
    nbCategories: sumItemInArrayObject(newList,'qte')
   }
  }
return copyShoppingCart;

};

const setValueByKey=(key,values)=>{
  try {
  let copyData = JSON.parse(localStorage.getItem("licencesData"));
    copyData[localStorage.getItem("codeShop")] = {...copyData[localStorage.getItem("codeShop")],[key]:values} 
    localStorage.setItem("licencesData", JSON.stringify(copyData));
  } catch (error) {
    console.error(error)
  }

}
const initialValueLicenceData = { shoppingCart:{
  total:0,
  listCategories:[],
  nbCategories:0,
},
numberOfAtemps: 0,
};
/**
 * Reducer function for managing settings state. 
 *
 * @param {Object} state - The current settings state.
 * @param {Object} action - The action to perform.
 * @param {string} action.type - The type of action.
 * @param {string} action.payload - The value to set for the action type.
 * @returns {Object} The updated settings state.
 */
function settingsReducer(state, action) {
  switch (action.type) { 
    case 'SHOPPING_CART':{

      const copyShoppingCart= handlerShoppingCart(action.payload,state?.licenceData.shoppingCart,action.payload?.method); 
      setValueByKey("shoppingCart", copyShoppingCart); 
      return { ...state,licenceData:{...state?.licenceData, shoppingCart: copyShoppingCart} };    
    }
    case "OPEN_SIDEBAR": 
      return { ...state, openSidebar: !state.openSidebar }; 
   case "MARGIN_TOP": 
        return { ...state, marginTop: action.payload }; 
    case "LANGUAGE":{
      localStorage.setItem("language", JSON.stringify(action.payload));
      return { ...state, language: action.payload }; 
    }    
    case  "NUMBER_OF_ATEMPS":{
      const newdata =  action.payload !== 0 ? (state?.numberOfAtemps || 0) + 1 : 0;
      localStorage.setItem("numberOfAtemps", JSON.stringify(newdata));
      setValueByKey("numberOfAtemps",newdata); 
      return { ...state,licenceData:{...state?.licenceData, numberOfAtemps: newdata} };    
      
    }
    case  "TEMPLATE_DATA":{ 
      console.log(action.payload)
      return { ...state,...action.payload}; 
    } 
    
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
} 

/**
 * Provider component for managing global state.
 * Provides context for settings state and dispatch function.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to render.
 * @returns {JSX.Element} The `GlobalStateProvider` component.
 */
const GlobalStateProvider = ({ children }) => { 


  const { allProductsCategories, getProductsCategories } = useGetProductsCategoriesApi(); 
  const {templateData,getTemplateData} = useGetTemplateDataApi();

  const [state, dispatch] = useReducer(settingsReducer, {
    openSidebar: false, 
    marginTop:"70px",
    isLoading:true,
    categories:[],
    templateData:{ deliveryDeadline: 2,currency:{symbol:"€",precision:2 ,templateCode:null} },
    language:JSON.parse(localStorage.getItem("language")) || 'FRENCH',
    licenceData:localStorage.getItem("licencesData") && JSON.parse(localStorage.getItem("licencesData")) && localStorage.getItem("codeShop") ?
    {...initialValueLicenceData, ...JSON.parse(localStorage.getItem("licencesData"))[localStorage.getItem("codeShop")]} :initialValueLicenceData ,  
  });  


useEffect(()=>{ 
   if(state?.licenceData?.token) {
  getProductsCategories();
  getTemplateData();
}
},[])
useEffect(()=>{ 
  if(templateData) {
    dispatch({
      type: "TEMPLATE_DATA",
      payload: ({templateData,categories:allProductsCategories,isLoading:false}),
    }); 
}
},[templateData])


  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalStateDispatchContext.Provider value={dispatch}> 
       {(!state?.isLoading || !state?.licenceData?.token) ? children : <LinearProgress color="inherit" />}
      </GlobalStateDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

/**
 * Hook to access the settings state.
 * Throws an error if used outside of `GlobalStateProvider`.
 *
 * @returns {Object} The settings state.
 */
const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (context === undefined) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider");
  }
  return context;
};

/**
 * Hook to access the settings dispatch function.
 * Throws an error if used outside of `GlobalStateProvider`.
 *
 * @returns {Function} The settings dispatch function.
 */
const useGlobalStateDispatch = () => {
  const context = useContext(GlobalStateDispatchContext);
  if (context === undefined) {
    throw new Error("useGlobalStateDispatch must be used within a GlobalStateProvider");
  }
  return context;
};

/**
 * Dispatches an action to update the global state. 
 *
 * @param {Function} dispatch - The dispatch function from `useReducer`.
 * @param {Object} payload - The payload to update in the state.
 */
const setGlobalStateOpenSidebar = (dispatch, payload) => {  
  dispatch({
    type: "OPEN_SIDEBAR",
    payload: payload,
  });
};
/**
 * Dispatches an action to update the global state. 
 *
 * @param {Function} dispatch - The dispatch function from `useReducer`.
 * @param {Object} payload - The payload to update in the state.
 */
const setGlobalStateMarginTop = (dispatch, payload) => {  
  dispatch({
    type: "MARGIN_TOP",
    payload: payload,
  });
};

const setShoppingCart = (dispatch, payload) => {  
  dispatch({
    type: "SHOPPING_CART",
    payload: payload,
  });
};
const setLanguage = (dispatch, payload) => {  
  dispatch({
    type: "LANGUAGE",
    payload: payload,
  });
};
const setNumberOfAtemps = (dispatch, payload) => {  
  dispatch({
    type: "NUMBER_OF_ATEMPS",
    payload: payload,
  });
};

export { GlobalStateProvider, useGlobalState, useGlobalStateDispatch,
   setGlobalStateOpenSidebar,
   setGlobalStateMarginTop,setShoppingCart,setLanguage,setNumberOfAtemps };

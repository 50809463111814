import AlertSnackbar from "components/AlertSnackbar/index";
import {useState, createContext} from "react";   
export const ErrorAndLoadingContext = createContext();


function ErrorHandlingAndLoading(props) {
  const [alertSnackbarData, setAlertSnackbarData] = useState({
    msg:'Operation successful',
    open:false,
    severity:"success"
  }); 

  return (
    <ErrorAndLoadingContext.Provider
      value={{
        snackbar:(open,msg,severity)=> {
        setAlertSnackbarData({open,msg,severity})
        },  
      }}
    > 
 
      <AlertSnackbar
        open={alertSnackbarData?.open}
        msg={alertSnackbarData?.msg}
        severity={alertSnackbarData?.severity}
        setOpen={()=>{
            setAlertSnackbarData((old)=>({...old,open:false}))
        }}
      />
      {props.children}
    </ErrorAndLoadingContext.Provider>
  );

}

export default ErrorHandlingAndLoading;
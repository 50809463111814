import { createTheme, } from '@mui/material/styles';
import { template1Palette, template2Palette } from "./colors";
import { themeEnums } from "utils/enums";  
//import { createBreakpoints, fontFamily, fontSize } from "@mui/system" 


//const breakpoints = createBreakpoints({});
 

const makeStyle = (palette) => ({
  ...palette,  
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiContainer:{
      styleOverrides:{ 
          root:{
          maxWidth:'100%' 
         },
         
      }
    },
    MuiOutlinedInput:{
      styleOverrides:{
        root:{
          padding:'4px 20px 5px',
          width: '100%',
          fontFamily:"DM-sans",
          border: `1px solid ${palette.border.input}`,
          borderRadius: "25px",
          height:'40px'
        },
      }
    },
    MuiInputBase:{
      styleOverrides:{ 
        input:{
          textTransform: 'none',
          padding:'4px 20px 5px', 
            '&:-webkit-autofill': { 
              width:"100%",
              height:40,
              'background-clip': 'text', 
            } 
         }, 
      }
    },
    MuiFormHelperText:{
      styleOverrides:{
        root:{ 
          position: 'absolute',
          top: '100%'
        }
      }
    },
    MuiCircularProgress:{
      styleOverrides:{
        root:{
          top: '50%',
          position: 'absolute',
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '0px',
          borderRadius: '12px',
        },
      },
    },
    MuiButton:{
      styleOverrides:{
        root:{ 
          textTransform: 'none',
          fontFamily:"DM-sans",
          fontSize:18,
          height:44,
          borderRadius:25, 
          color:palette.text.primaryButton, 
          border:`1px solid ${palette.border.header}`,  
          "&:hover":{ 
            color:palette.text.colorForeground, 
            background:palette.background.Buttom,
            border:`1px solid ${palette.border.header}`, 
            opacity:0.5
  
          }
        },
        contained:{
          color:palette.text.colorForeground, 
          background:palette.background.Buttom, 
        },
        text:{ 
          color:palette.text.primaryButton, 
          border:`none`, 
          "&:hover":{  
            color:palette.text.primaryButton, 
            background:'transparent',
            border:`none`, 
            textDecoration: 'underline',
            opacity:1
  
          }
        },
 

      } 
    },
    MuiIconButton :{
    styleOverrides:{
      root:{

        color:palette.text.primaryButton, 
      }
    }
  
  },
    MuiTypography:{
      styleOverrides:{
        root: {
        color:palette.text.primary, 
        }
      }
    }, 
    MuiAppBar: {
      styleOverrides: {

        root: {
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: palette.text.unchecked , // Light grey for unchecked state
          '&.Mui-checked': {
            color:  palette.text.checked ,  // Primary color for checked state
          },
          '&:hover': {
            backgroundColor: palette.background.hover.checkedbox , // Subtle hover effect
          },
          '&.Mui-focusVisible': {
            outline: `2px solid ${palette.border.focus.checkedbox}`, // Focus outline color
          },
          '&.Mui-disabled': {
            color: palette.text.disabled.checkedbox, // Light grey for disabled state
          },
        },
      },
    },
  },/*
    {
      color: theme.palette.grey[500], // Unchecked state
      '&.Mui-checked': {
        color: theme.palette.primary.main, // Checked state
      },
      '&:hover': {
        backgroundColor: theme.palette.action.hover, // Subtle hover effect
      },
      '&.Mui-disabled': {
        color: theme.palette.grey[300], // Disabled state
      },
      '&.Mui-focusVisible': {
        outline: `2px solid ${theme.palette.primary.main}`, // Focus state
      },
    }

  },*/
});
 


/**
 * An object containing predefined MUI themes for template1 and template2 modes.
 * Uses the `createTheme` function to generate themes based on color palettes.
 *
 * @type {Object}
 * @property {Theme} template1 - The light theme created with `template1Palette`.
 * @property {Theme} template2 - The dark theme created with `template2Palette`.
 */
const themes = {
  [themeEnums.template1]: createTheme(makeStyle(template1Palette)),
  [themeEnums.template2]: createTheme(template2Palette),
};




/**
 * Retrieves the MUI theme based on the provided theme type.
 *
 * @param {string} theme - The type of theme to retrieve (e.g., 'TEMPLATE_1' or 'TEMPLATE_2 ...).
 * @returns {Theme} The MUI theme corresponding to the provided theme type.
 *
 * @example
 * const currentTheme = getTheme(themeEnums.light);
 */
export default function getTheme(theme) {
  return themes[theme];
}
